import { TextInput } from '@mantine/core';

interface TextFieldProps {
  placeholderText?: string;
  text: string;
  setText?: (val: string) => void;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  textAlign?: 'left' | 'center' | 'right';
  disabled?: boolean;
  className?: string;
  pattern?: string;
}

function TextField({
  placeholderText,
  text,
  setText,
  disabled,
  className,
  size,
  textAlign,
  pattern,
}: TextFieldProps) {
  return (
    <TextInput
      placeholder={placeholderText}
      disabled={disabled}
      value={text}
      onChange={(event) => {
        const newValue = event.currentTarget.value;
        if (setText) {
          if (pattern) {
            const regex = new RegExp(pattern);
            if (!regex.test(newValue)) return;
          }
          setText(newValue);
        }
      }}
      className={className}
      size={size ?? 'lg'}
      styles={{ input: { textAlign } }}
    />
  );
}

export default TextField;
