/* eslint-disable no-continue */
import * as React from 'react';
import {
  IConsultationOutcome,
  IConsultationOutcomeGroup,
} from '../../hooks/promises/getConsultationOutcomes';
import { Checkbox } from './Checkbox';

export interface ICheckboxListProps {
  items: IConsultationOutcome[];
  selectedItems: IConsultationOutcome[];
  setSelectedItems: (items: IConsultationOutcome[]) => void;
  setOtherText: (customOutcome: string) => void;
  otherText: string;
}

const extractGroups = (
  items: IConsultationOutcome[]
): IConsultationOutcomeGroup[] => {
  function unique(array: IConsultationOutcomeGroup[]) {
    return array.filter(
      (e, i) =>
        array.findIndex(
          (a) => a.consultationOutcomeGroupId === e.consultationOutcomeGroupId
        ) === i
    );
  }
  return unique(items.map((x) => x.consultationOutcomeGroup));
};

export function CheckboxList({
  items,
  selectedItems,
  setSelectedItems,
  setOtherText,
  otherText,
}: ICheckboxListProps) {
  const [checkListLocked, setCheckListLocked] = React.useState(false);
  const groups = extractGroups(items);

  React.useEffect(() => {
    setCheckListLocked(selectedItems.length >= 3);
  }, [selectedItems]);

  const onItemClick = (checked: boolean, item: IConsultationOutcome) => {
    if (checked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(
        selectedItems.filter(
          (i) => i.consultationOutcomeId !== item.consultationOutcomeId
        )
      );
    }
  };

  React.useEffect(() => {
    const uniqueItems = selectedItems.filter(
      (e, i) =>
        selectedItems.findIndex(
          (a) => a.consultationOutcomeId === e.consultationOutcomeId
        ) === i
    );

    if (selectedItems.length === uniqueItems.length) return;
    setSelectedItems(
      selectedItems.filter(
        (e, i) =>
          selectedItems.findIndex(
            (a) => a.consultationOutcomeId === e.consultationOutcomeId
          ) === i
      )
    );
  }, [selectedItems]);

  return (
    <div>
      <div>
        {groups.map((group) => {
          const children = items.filter(
            (x) =>
              x.consultationOutcomeGroup.consultationOutcomeGroupId ===
                group.consultationOutcomeGroupId && !x.deletedFlag
          );

          if (children.length === 0) return null;

          return (
            <div
              className="px-8 py-6 mb-4 bg-white border rounded-md shadow-md"
              key={group.consultationOutcomeGroupDescription}
            >
              <h1 className="mb-4 text-2xl">
                {group.consultationOutcomeGroupDescription}
              </h1>
              {children
                .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
                .map((x) => (
                  <Checkbox
                    item={x}
                    key={x.consultationOutcomeDescription}
                    disabled={checkListLocked}
                    startChecked={
                      selectedItems.filter(
                        (y) =>
                          y.consultationOutcomeId === x.consultationOutcomeId
                      ).length > 0
                    }
                    onClick={(checked) => onItemClick(checked, x)}
                    onInputText={
                      x.consultationOutcomeId ===
                      Number(process.env.REACT_APP_OUTCOME_OTHER_BOX_ID)
                        ? setOtherText
                        : undefined
                    }
                    defaultText={otherText}
                  />
                ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}
