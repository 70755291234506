import { useMemo } from 'react';
import {
  deleteFavourite,
  getFavourites,
  postFavourite,
} from '../promises/getFavourites';
import useApiResult from './useApiResult';

function useFavourites() {
  const favouritesResults = useMemo(() => getFavourites(), []);
  const { loading, error, result } = useApiResult(favouritesResults);

  async function addFavourite(organisationId: number) {
    await postFavourite(organisationId);
  }

  async function removeFavourite(organisationId: number) {
    await deleteFavourite(organisationId);
  }

  return { loading, error, favourites: result, addFavourite, removeFavourite };
}

export default useFavourites;
