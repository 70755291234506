import { useMsal } from '@azure/msal-react';
import { useDebouncedValue } from '@mantine/hooks';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { hasRole, UserRole } from '../../utils/security';

export interface IRequireAuthProps {
  requireRole?: UserRole[];
  children: React.ReactNode;
}

export function RequireAuth({ requireRole, children }: IRequireAuthProps) {
  const { instance } = useMsal();
  const user = instance.getActiveAccount();
  const [debouncedUser] = useDebouncedValue(user, 200);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!debouncedUser) navigate('/login');

    if (!requireRole) return;

    const roleMatches = requireRole.filter((x) => hasRole(instance, x));
    if (roleMatches.length === 0)
      navigate('/403', {
        replace: true,
      });
  }, [debouncedUser]);

  return <div>{user && children}</div>;
}
