import { useMemo } from 'react';
import useApiResult from './common/useApiResult';
import { getPrimaryCareNetworks } from './promises/getGeneralPractices';

function useGetPrimaryCareNetworks(query: string) {
  const pcnResults = useMemo(() => getPrimaryCareNetworks(query), [query]);
  const { loading, error, result } = useApiResult(pcnResults);

  return { loading, error, pcns: result };
}

export default useGetPrimaryCareNetworks;
