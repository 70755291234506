import React from 'react';
import { ForbidAuth } from '../components/AuthWrapper/ForbidAuth';
import { login } from '../utils/security';
import setTitle from '../utils/title';

function LoginButton() {
  return (
    <div
      onClickCapture={() => login()}
      className="flex flex-row items-center px-4 py-3 text-white transition rounded-md cursor-pointer bg-primary-500 hover:bg-primary-400"
    >
      <div className="pl-4 text-lg font-semibold">
        Login with Microsoft Account
      </div>
    </div>
  );
}

function LoginPage() {
  setTitle('Login');
  return (
    <ForbidAuth>
      <div className="flex flex-row">
        <div className="bg-primary-500 w-[50%] h-screen flex items-center justify-center">
          <img
            src="/logo.png"
            alt="Physio App Logo"
            title={`${process.env.REACT_APP_ENVIRONMENT_FRIENDLY}`}
          />
        </div>
        <div className="w-[50%] h-screen flex items-center justify-center bg-[#F6F6F6]">
          <div className="m-2 shadow-lg rounded-md w-[400px] justify-center p-8 bg-white">
            <div className="text-center">
              <h1 className="text-2xl font-semibold text-gray-800">
                Log in to continue
              </h1>
              <div className="mt-8">
                <LoginButton />
              </div>
              <div>
                <div className="relative flex items-center py-5">
                  <div className="flex-grow border-t border-gray-400" />
                  <span className="flex-shrink mx-4 mb-1 text-subtitle">
                    or
                  </span>
                  <div className="flex-grow border-t border-gray-400" />
                </div>
              </div>
              <div className="">
                <p className="text-gray-800">
                  <a href="/" className="font-semibold text-primary-500">
                    Contact us
                  </a>{' '}
                  to create an account.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ForbidAuth>
  );
}

export default LoginPage;
