import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from '../../authConfig';

export interface IAuthWrapperProps {
  children: React.ReactNode;
}

const publicClientApplication = new PublicClientApplication(msalConfig);

export function AuthWrapper({ children }: IAuthWrapperProps) {
  return (
    <MsalProvider instance={publicClientApplication}>{children}</MsalProvider>
  );
}

export { publicClientApplication };
